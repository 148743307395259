import Head from 'next/head';
import React from 'react';
import type { Organization, WithContext } from 'schema-dts';
import {
  FdrOrgFragment,
  useFdrOrganizationQuery,
} from 'schemas/query/organozation/fdr-orgnization.generated';

const structuredDataForOrganization = ({
  name,
  url,
  logo: flogo,
  phone,
  place,
  postalCode,
  region,
  street,
}: FdrOrgFragment): WithContext<Organization> => {
  const logo = flogo?.resized[0]?.link?.url;

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name,
    url,
    logo,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phone,
      contactType: `customer service`,
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: place,
      addressRegion: region,
      postalCode,
      streetAddress: street,
    },
  };
};

let FdrStructuredDataOrganization: React.FC = () => null;

// structured data is not needed in browser
if (!process.browser) {
  // eslint-disable-next-line react/display-name
  FdrStructuredDataOrganization = (): React.ReactElement => {
    const { data } = useFdrOrganizationQuery();

    if (!data?.fdrOrganization) return null;

    return (
      <Head>
        <script
          key="org-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              structuredDataForOrganization(data.fdrOrganization)
            ),
          }}
        />
      </Head>
    );
  };
}

export { FdrStructuredDataOrganization };
