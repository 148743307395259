import type { NextPage } from 'next';
import React from 'react';
import { FdrPageComponents } from 'components/domain/fdr-page-components';
import { FdrStructuredDataOrganization } from 'components/domain/fdr-strucured-data';
import { fdrHomepageVars } from 'components/page/home/home.vars';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { useFdrHomePageQuery } from 'schemas/query/home/fdr-home-page.generated';

export { getServerSideProps } from 'components/page/home/home.gssp';

const HomePage: NextPage<{
  components: FdrPageComponentsFragment['components'];
}> = () => {
  const { data } = useFdrHomePageQuery({ variables: fdrHomepageVars });

  const components = data?.fdrHomePage?.pageComponents?.components || [];

  return (
    <>
      <FdrPageComponents components={components} />
      <FdrStructuredDataOrganization />
    </>
  );
};

export default HomePage;
